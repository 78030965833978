import {createContext, useContext, useState} from 'react';

const DataContext = createContext();

const DataProvider = ({children}) => {
  // DATA
  const [sidebar, setSidebar] = useState(true);
  const [myUser, setMyUser] = useState({id: -1, name: "", username: ""});
  const [users, setUsers] = useState([
    {id: 1001, name: "Tüyak Admin", username: "tuyak", phone1: "5348931183", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1002, name: "İsa Talha Mengi", username: "tuyak_itm", phone1: "5348931183", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1003, name: "Furkan Erhan", username: "tuyak_fe", phone1: "5388249401", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1004, name: "Deneme Çalışan", username: "deneme1", phone1: "5301234567", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1005, name: "Deneme Çalışan", username: "deneme2", phone1: "5301234568", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1006, name: "Deneme Çalışan", username: "deneme3", phone1: "5301234569", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1007, name: "Deneme Çalışan", username: "deneme4", phone1: "5301234566", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1008, name: "Deneme Çalışan", username: "deneme5", phone1: "5301234565", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1009, name: "Deneme Çalışan", username: "deneme6", phone1: "5301234564", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1010, name: "Deneme Çalışan", username: "deneme7", phone1: "5301234563", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0},
    {id: 1011, name: "Deneme Çalışan", username: "deneme8", phone1: "5301234562", phone2: "", addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", password: "12345", status: 'Active', prim: 0}
  ]);
  const [customers, setCustomers] = useState([
    {id: 1001, name: "Emir", surname: "Aksoydan", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236547", phone2: '5380723398', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1002, name: "Batuhan", surname: "Umay", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236548", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1003, name: "Muhammed Ali", surname: "Alarslan", type: "Bireysel", identityNo: '12345678912', taxNo: '', phone1: "5301236549", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1004, name: "Mehmet", surname: "Erdoğdu", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236546", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1005, name: "Mustafa", surname: "Tüfekçi", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236545", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1006, name: "İskender", surname: "Ak", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236544", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1007, name: "Soner", surname: "Gezgin", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236543", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1008, name: "Kurumsal", surname: "Müşteri", type: "Kurumsal", identityNo: '', taxNo: '1234567890', phone1: "5300123456", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1009, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236541", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1010, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236540", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1011, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236550", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1012, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236551", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1013, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236552", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1014, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236553", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1015, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236554", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1016, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236555", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1017, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236556", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1018, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236557", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1019, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236558", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1020, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236559", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1021, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236500", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'},
    {id: 1022, name: "Deneme", surname: "Müşteri", type: "Bireysel", identityNo: '12345678912', taxNo: '', taxOffice: '', phone1: "5301236520", phone2: '', addressCity: "İstanbul", addressDistrict: "Beylikdüzü", addressText: "Osmangazi Mahallesi Fevzi Çakmak Caddesi No 10/B", createdAt: "01.10.2024", status: 'Active'}
  ]);
  const [brands, setBrands] = useState([
    {id: 1001, name: "Samsung", describe: ""},
    {id: 1002, name: "Vestel", describe: ""},
    {id: 1003, name: "Arçelik", describe: ""},
    {id: 1004, name: "Beko", describe: ""}
  ]);
  const [devices, setDevices] = useState([
    {id: 1001, device: "Televizyon", describe: ""},
    {id: 1002, device: "Buz Dolabı", describe: ""},
    {id: 1003, device: "Fırın", describe: ""},
    {id: 1004, device: "Çamaşır Makinesi", describe: ""},
    {id: 1005, device: "Bulaşık Makinesi", describe: ""}
  ]);
  const [services, setServices] = useState([
    {id: 1001, userId: 1002, customerId: 1001, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD1", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1002, userId: 1002, customerId: 1002, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD2", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1003, userId: 1003, customerId: 1003, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD3", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1004, userId: 1003, customerId: 1003, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD4", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1005, userId: 1002, customerId: 1004, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD5", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1006, userId: 1002, customerId: 1001, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD6", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'},
    {id: 1007, userId: 1001, customerId: 1007, brandId: 1003, deviceId: 1002, model: "ABC10023123", problem: "Cihaz Soğutmuyor", note: "ABCD7", warranty: 12, customerAviableDate: '10.10.2024', customerAviableTimeStart: '16.00', customerAviableTimeEnd: '19.00', serviceDate: '10.10.2024', serviceTime: '17.30', status: 'Tamamlandı', emergency: false, createdAt: '07.10.2024', updatedAt: '07.10.2024'}
  ]);
  const [ warehouses, setWarehouses ] = useState([
    {id: 1001, warehouse: "Depo 1", shelf: "Raf 27", barcode: "564857966", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelık Vestel Çamaşır 142810", quantity: 546, price: 350},
    {id: 1002, warehouse: "Depo 1", shelf: "Raf 28", barcode: "564857967", brand: "Vestel", device: "Buz Dolabı", model: "Tekmelik Vestel Buz 142810", quantity: 546, price: 350},
    {id: 1003, warehouse: "Depo 1", shelf: "Raf 28", barcode: "564857968", brand: "Vestel", device: "Bulaşık Makinesi", model: "Tekmelik Vestel Bulaşık 142810", quantity: 546, price: 350},
    {id: 1004, warehouse: "Depo 1", shelf: "Raf 29", barcode: "564857969", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142810", quantity: 546, price: 350},
    {id: 1005, warehouse: "Depo 2", shelf: "Raf 11", barcode: "564857961", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142812", quantity: 546, price: 350},
    {id: 1006, warehouse: "Depo 2", shelf: "Raf 11", barcode: "564857962", brand: "Arçelik", device: "Bulaşık Makinesi", model: "Tekmelik Arçelik Bulaşık 142812", quantity: 546, price: 350},
    {id: 1007, warehouse: "Depo 2", shelf: "Raf 12", barcode: "564857963", brand: "Vestel", device: "Fırın", model: "Vestel Fırın 142810", quantity: 546, price: 350},
    {id: 1008, warehouse: "Depo 2", shelf: "Raf 14", barcode: "564857964", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142810", quantity: 546, price: 350},
    {id: 1009, warehouse: "Depo 2", shelf: "Raf 14", barcode: "564857965", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142812", quantity: 546, price: 350},
    {id: 1010, warehouse: "Depo 2", shelf: "Raf 16", barcode: "564857970", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142814", quantity: 546, price: 350},
    {id: 1011, warehouse: "Depo 2", shelf: "Raf 19", barcode: "564857971", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142816", quantity: 546, price: 350},
    {id: 1012, warehouse: "Depo 1", shelf: "Raf 26", barcode: "564857972", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142818", quantity: 546, price: 350},
    {id: 1013, warehouse: "Depo 1", shelf: "Raf 26", barcode: "564857973", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142822", quantity: 546, price: 350},
    {id: 1014, warehouse: "Depo 1", shelf: "Raf 29", barcode: "564857974", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142900", quantity: 546, price: 350},
    {id: 1015, warehouse: "Depo 3", shelf: "Raf 08", barcode: "564857975", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142920", quantity: 546, price: 350},
    {id: 1016, warehouse: "Depo 3", shelf: "Raf 10", barcode: "564857976", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142940", quantity: 546, price: 350},
    {id: 1017, warehouse: "Depo 3", shelf: "Raf 12", barcode: "564857977", brand: "Arçelik", device: "Çamaşır Makinesi", model: "Tekmelik Arçelik Çamaşır 142980", quantity: 546, price: 350},
    {id: 1018, warehouse: "Depo 3", shelf: "Raf 12", barcode: "564857978", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142811", quantity: 546, price: 350},
    {id: 1019, warehouse: "Depo 3", shelf: "Raf 13", barcode: "564857979", brand: "Vestel", device: "Çamaşır Makinesi", model: "Tekmelik Vestel Çamaşır 142813", quantity: 546, price: 350}
  ]);
  const values = {sidebar, setSidebar, myUser, setMyUser, users, setUsers, customers, setCustomers, brands, setBrands, devices, setDevices, services, setServices, warehouses, setWarehouses}
  return <DataContext.Provider value={values}>{children}</DataContext.Provider>
}

const useData = () => useContext(DataContext);

export {DataProvider, useData};