// General
import React from 'react';
// Table
import Table from 'react-bootstrap/Table';
// Chart
import Chart from 'react-apexcharts';
// Icons
import { FiBarChart, FiSettings, FiPackage } from "react-icons/fi";


function Dashboard() {
  const colors = ["success", "secondary", "info", "primary", "warning", "warning", "danger", "danger"];
  const colorsReverse = ["danger", "danger", "warning", "warning", "primary", "info", "secondary", "success"];
  // Chart
  const data_x = [145, 96, 108, 140, 130, 150, 170, 138, 114, 128, 138];
  const data_y = ["01/01/2024", "02/01/2024", "03/01/2024", "04/01/2024", "05/01/2024", "06/01/2024", "07/01/2024", "08/01/2024", "09/01/2024", "10/01/2024", "11/01/2024"];
  const options = {
    chart: { type: "bar", height: 307, parentHeightOffset: 0, toolbar: { show: false } },
    colors: ["#537AEF"],
    series: [{ name: "Sales", data: data_x }],
    fill: { opacity: 1 },
    plotOptions: { bar: { columnWidth: "50%", borderRadius: 4, borderRadiusApplication: "end", borderRadiusWhenStacked: "last", dataLabels: { position: "top", orientation: "vertical" } } },
    grid: { strokeDashArray: 4, padding: { top: -20, right: 0, bottom: -4 }, xaxis: { lines: { show: true } } },
    xaxis: { type: "datetime", categories: data_y, axisTicks: { color: "#f0f4f7" } },
    yaxis: { title: { text: "Number of Sales", style: { fontSize: "12px", fontWeight: 600 } } },
    tooltip: { theme: "light" },
    legend: { position: "top", show: true, horizontalAlign: "center" },
    stroke: { width: 0 },
    dataLabels: { enabled: false },
    theme: { mode: "light" }
  };

  const mostUsedUnits = () => {
    const rows = [];
    for (let i = 8; i > 0; i--) {
      rows.push(
        <tr key={i}>
          <td>Geçici Veri {i}</td>
          <td>{i}</td>
          <td className="w-50">
            <div className="progress progress-md mt-0">
              <div className={`progress-bar bg-${colorsReverse[i - 1]}`} style={{ width: `${((i / 36) * 100).toFixed(2)}%` }}></div>
            </div>
          </td>
        </tr>
      );
    }
    return rows;
  };

  const mostWievedDistricts = () => {
    const rows = [];
    for (let j = 8; j > 0; j--) {
      rows.push(
        <tr key={j}>
          <td>İlçe {j}</td>
          <td>{j}</td>
          <td className="w-50">
            <div className="progress progress-md mt-0">
              <div className={`progress-bar bg-${colorsReverse[j - 1]}`} style={{ width: `${((j / 36) * 100).toFixed(2)}%` }}></div>
            </div>
          </td>
        </tr>
      );
    }
    return rows;
  };

  // HTML
  return (
    <div className='container-xxl '>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Anasayfa</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-xl-12'>
          <div className="row g-3">
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <div className="fs-14 mb-1 ">Toplam Müşteri</div>
                  </div>
                  <div className="text-center">
                    <div className="fs-22 mb-0 me-2 fw-semibold text-black">132.000 Kişi</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <div className="fs-14 mb-1 ">Toplam Çalışan</div>
                  </div>
                  <div className="text-center">
                    <div className="fs-22 mb-0 me-2 fw-semibold text-black">50 Kişi</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <div className="fs-14 mb-1 ">Toplam Servis</div>
                  </div>
                  <div className="text-center">
                    <div className="fs-22 mb-0 me-2 fw-semibold text-black">5000 Adet</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <div className="fs-14 mb-1 ">Kasa</div>
                  </div>
                  <div className="text-center">
                    <div className="fs-22 mb-0 me-2 fw-semibold text-black">172.000 ₺</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-xl-12'>
          <div className='card overflow-hidden'>
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="border border-dark rounded-2 me-2 widget-icons-sections">
                  <FiSettings className="widgets-icons" />
                </div>
                <h5 className="card-title mb-0">Son 3 gün gidilen servis sayısı</h5>
              </div>
            </div>
            <div className='card-body  p-0'>

              <Table responsive striped className="w-100  overflow-hidden text-center  mb-0">
                <thead>
                  <tr>
                    <th>Gidilen Tarih</th>
                    <th>Gidilen Servis Sayısı</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>20.10.2024 <b>(Bugün)</b> </td><td>20</td></tr>
                  <tr><td>19.10.2024 <b>(Dün)</b></td><td>30</td></tr>
                  <tr><td>18.10.2024 <b>(Önceki Gün)</b></td><td>50</td></tr>
                </tbody>
              </Table>

            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="border border-dark rounded-2 me-2 widget-icons-sections">
                  <FiBarChart className="widgets-icons" />
                </div>
                <h5 className="card-title mb-0">Aylık Servis Sayısı</h5>
              </div>
            </div>
            <div className="card-body">
              <Chart options={options} series={options.series} type="bar" height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xl-6">
          <div className="card overflow-hidden">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="border border-dark rounded-2 me-2 widget-icons-sections">
                  <FiSettings className="widgets-icons" />
                </div>
                <h5 className="card-title mb-0">En Çok Kullanılan Parçalar</h5>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <Table className="table table-traffic mb-0">
                  <thead>
                    <tr>
                      <th>Parça Adı</th>
                      <th colSpan="2">Adet</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostUsedUnits()}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-6">
          <div className="card overflow-hidden">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="border border-dark rounded-2 me-2 widget-icons-sections">
                  <FiPackage className="widgets-icons" />
                </div>
                <h5 className="card-title mb-0">En Çok Ziyaret Edilen İlçeler</h5>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-traffic mb-0">
                  <thead>
                    <tr>
                      <th>İlçe Adı</th>
                      <th colSpan="2">Ziyaret Miktarı</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostWievedDistricts()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;