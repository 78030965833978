// General
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Context
import { useData } from '../context/DataContext';
import { useAuth } from '../context/AuthContext';
// Components
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

function Layout() {
  // Context Import
  const { sidebar } = useData();
  const { isLoggedIn } = useAuth();
  // Mobile Form
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  // HTML
  return (
    <div className='main-wrapper'>
      {isLoggedIn ? (
        <div className='app-layout'>
          <Header />
          <Sidebar />
          <div className='content-page m-0 p-0'>
            <div className="content mb-4" style={{ paddingTop: '70px', paddingLeft: `${!sidebar || isMobile ? '0px' : '265px'}` }}>
              <Outlet />
            </div>
            <Footer />

          </div>

        </div>
      ) : (
        <Navigate to='/girisyap' />
      )}
    </div>
  );
}

export default Layout;