// General
import React, { useCallback, useEffect, useState } from 'react';

// React-Bootstrap
import { Table } from 'react-bootstrap';

// Icons
import { HiMiniChevronDown } from "react-icons/hi2";
import { TbBook2, TbPrinter, } from "react-icons/tb";
import { CiCalendar } from "react-icons/ci";
import { RiFolderSettingsLine, } from "react-icons/ri";
import { FiFolderMinus } from "react-icons/fi";

//Material-Ui
import { TextField } from '@mui/material';

function Reminder() {

  // Data
  const data = [
    { id: 1001, message: 'mesaj 1', date: '12.02.2024', remainingTime: '20', repeatTime: '10', employee: 'Emir', performerOfThetransaction: 'İsa', status: 'Tamamlandı', }, { id: 1001, message: 'mesaj 2', date: '12.02.2024', remainingTime: '20', repeatTime: '10', employee: 'İsa', performerOfThetransaction: 'Furkan', status: 'Aktif', },];
  const [combinedData, setCombinedData] = useState([]);
  useEffect(() => {
    const combined = data.map((d) => {
      const id = d.id;
      const reminder = { message: d.message, date: d.date, remainingTime: d.remainingTime, repeatTime: d.repeatTime, employee: d.employee, performerOfThetransaction: d.performerOfThetransaction, status: d.status, };
      return { id: id, reminder: reminder, };
    });
    setCombinedData(combined);
  }, [data]);

  // Operations Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);

  // Data Filtering
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchCategories = [
    { layer: 1, layers: ["id"] },
    { layer: 2, layers: ["reminder", "message"] },
    { layer: 2, layers: ["reminder", "date"] },
    { layer: 2, layers: ["reminder", "remainingTime"] },
    { layer: 2, layers: ["reminder", "repeatTime"] },
    { layer: 2, layers: ["reminder", "employee"] },
    { layer: 2, layers: ["reminder", "performerOfThetransaction"] },
    { layer: 2, layers: ["reminder", "status"] },
  ]
  useEffect(() => {
    const filtered = combinedData.filter((item) => {
      return searchCategories.some((category) => {
        if (category.layer === 2) {
          const value = item[category.layers[0]]?.[category.layers[1]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        } else {
          const value = item[category.layers[0]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        }
      });
    });
    setFilteredData(filtered);
  }, [combinedData, searchText]);

  // Data Pagination
  const [slicedData, setSlicedData] = useState([]);
  const pagesize = 8;
  const totalPages = Math.ceil(filteredData.length / pagesize);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const startIndex = (currentPage - 1) * pagesize;
    const sliced = filteredData.slice(startIndex, startIndex + pagesize);
    setSlicedData(sliced);
  }, [filteredData, pagesize, currentPage]);
  const handlePrevPage = () => { setCurrentPage(currentPage - 1); }
  const handleNextPage = () => { setCurrentPage(currentPage + 1); }
  const handleGoToPage = (page) => { setCurrentPage(page); }
  // HTML
  return (
    <div className='container-xxl'>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Hatırlatıcı</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <h5 className='card-title mb-0"'>Hatırlatıcı</h5>
              <div className="btn-group">
                <button className="btn btn-success rounded operationsdropdown d-flex align-items-center" onClick={toggleOperationsDropdown}><TbBook2 style={{ fontSize: '1.5em' }} />İşlemler<HiMiniChevronDown style={{ fontSize: '1.25em' }} /></button>
                {operationsDropdown && (
                  <div className="dropdown-menu operationsdropdown show" style={{ position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 40px)' }}>
                    <button className="dropdown-item"><CiCalendar style={{ fontSize: '1.5em' }} /> Hatırlatıcı Ekle</button>
                    <button className="dropdown-item"><TbPrinter style={{ fontSize: '1.5em' }} />  Yazdır</button>
                  </div>
                )}
              </div>
            </div>
            <div className='card-body'>
              <div className='row d-flex justify-content-end mb-3'>
                <div className='col-xs-12 col-md-3'>
                  <TextField
                    label="Ara"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth />
                </div>
              </div>
              <Table responsive striped bordered className="w-100">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>MESAJ</th>
                    <th>TARİH</th>
                    <th>KALAN ZAMAN</th>
                    <th>TEKRAR SÜRESİ</th>
                    <th>PERSONEL</th>
                    <th>İŞLEMİ YAPAN</th>
                    <th>STATUS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {slicedData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className='align-middle text-center'>{data.id}</td>
                        <td className='align-middle text-center'>{data.reminder.message}</td>
                        <td className='align-middle text-center'>{data.reminder.date}</td>
                        <td className='align-middle text-center'>{data.reminder.remainingTime}</td>
                        <td className='align-middle text-center'>{data.reminder.repeatTime}</td>
                        <td className='align-middle text-center'>{data.reminder.employee}</td>
                        <td className='align-middle text-center'>{data.reminder.performerOfThetransaction}</td>
                        <td className='align-middle text-center'>{data.reminder.status}</td>
                        <td className='align-middle text-center'>
                          <div className="d-flex justify-content-center">
                            <button className="button-with-icon btn btn-primary mx-1"><RiFolderSettingsLine className='button-icon' />Güncelle</button>
                            <button className="button-with-icon btn btn-danger mx-1"><FiFolderMinus className='button-icon' />Sil</button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reminder;