// General
import React from 'react';

function Statistics() {
  // HTML
  return (
    <div>Statistics</div>
  );
}

export default Statistics;