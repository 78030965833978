import { createContext, useContext, useEffect, useState } from 'react';
import { useData } from './DataContext';
import { useToast } from './ToastContext';
import { FaDoorClosed, FaDoorOpen } from 'react-icons/fa';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const { users, setMyUser } = useData();
  const { addToast } = useToast();
  const [ username, setUsername ] = useState("X");
  const [ password, setPassword ] = useState("X");
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  useEffect(() => {
    if(username === "" && password === ""){
      setMyUser({id: -1, name: "", email: ""});
      setIsLoggedIn(false);
      addToast({ background: 'danger', icon: <FaDoorClosed style={{fontSize: '1.5em'}} />, message: 'Çıkış Yaptınız!', delay: 2000 });
    }
    else {
      const user = users.find(user => user.username === username && user.password === password);
      if (user) {
        setIsLoggedIn(true);
        setMyUser({id: user.id, name: user.name, username: user.username});   
        addToast({ background: 'success', icon: <FaDoorOpen style={{fontSize: '1.5em'}} />, message: 'Başarı ile giriş Yaptınız!', delay: 2000 });
      } else {
        setIsLoggedIn(false);
      }
    }
  }, [users, username, password]);
  useEffect(() => {
    if(isLoggedIn){
      
    }
  }, [isLoggedIn]);
  const values = { setUsername, setPassword, isLoggedIn };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };