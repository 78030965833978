// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Icons
import { TbFolderX, TbArrowBackUpDouble } from "react-icons/tb";

function ServiceDeleteModal({show, handleClose, handleDelete, inputData}) {
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Servisi Silmek İstiyor Musunuz?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center'>
          <h6>Seçmiş olduğunuz {inputData} id numaralı servisi silmek istediğinize emin misiniz?</h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row g-2 d-flex justify-content-end align-items-center w-100'>
          <div className='col-xs-12 col-md-3'>
            <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
          </div>
          <div className='col-xs-12 col-md-3'>
            <button className='button-with-icon btn btn-danger w-100' onClick={() => handleDelete(inputData)}><TbFolderX className='button-icon' />Sil</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceDeleteModal;