// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Icons
import { TbHeartFilled } from "react-icons/tb";

function Footer() {
  // General Variables
  const currentYear = new Date().getFullYear();
  // HTML
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col fs-13 text-muted text-center">
            &copy; {currentYear} - Made with <TbHeartFilled className='text-danger p-0 m-0' style={{fontSize: '1.2em'}} /> by <NavLink to="/" className="text-reset fw-semibold">TÜYAK</NavLink> 
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;