// General
import React, { useCallback, useEffect, useState } from 'react';
// React-Bootstrap
import { Table } from 'react-bootstrap';
// Icons
import { TbBook2, TbPrinter, TbUserPlus, TbUserMinus, TbUserCog, TbUserExclamation } from "react-icons/tb";
import { HiMiniChevronDown } from "react-icons/hi2";
//Material-Ui
import { TextField } from '@mui/material';



function Customers() {
  // Data
  const data = [
    { id: 1001, customerFullname: 'Emir Aksoydan', customerPhone1: '5380723398', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', customerAddress: 'Süleyman Mahallesi DigiEye Sokak No 7 Daire 1' }, { id: 1002, customerFullname: 'İsa Mengi', customerPhone1: '5380723395', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', customerAddress: 'Süleyman Mahallesi DigiEye Sokak No 7 Daire 1' }, { id: 1003, customerFullname: 'Furkan Erhan', customerPhone1: '5380723398', customerCity: 'İstanbul', customerDistrict: 'Beylikdüzü', customerAddress: 'Süleyman Mahallesi DigiEye Sokak No 7 Daire 1' }];
  const [combinedData, setCombinedData] = useState([]);
  useEffect(() => {
    const combined = data.map((d) => {
      const id = d.id;
      const customer = { fullname: d.customerFullname, phone: d.customerPhone1, address: d.customerAddress, district: d.customerDistrict, city: d.customerCity };
      return { id: id, customer: customer, };
    });
    setCombinedData(combined);
  }, [data]);

  // Operations Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);

  // Data Filtering
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchCategories = [
    { layer: 1, layers: ["id"] },
    { layer: 2, layers: ["customer", "fullname"] },
    { layer: 2, layers: ["customer", "phone"] },
    { layer: 2, layers: ["customer", "address"] },
    { layer: 2, layers: ["customer", "district"] },
    { layer: 2, layers: ["customer", "city"] },
  ]
  useEffect(() => {
    const filtered = combinedData.filter((item) => {
      return searchCategories.some((category) => {
        if (category.layer === 2) {
          const value = item[category.layers[0]]?.[category.layers[1]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        } else {
          const value = item[category.layers[0]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        }
      });
    });
    setFilteredData(filtered);
  }, [combinedData, searchText]);
  // Data Pagination
  const [slicedData, setSlicedData] = useState([]);
  const pagesize = 8;
  const totalPages = Math.ceil(filteredData.length / pagesize);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const startIndex = (currentPage - 1) * pagesize;
    const sliced = filteredData.slice(startIndex, startIndex + pagesize);
    setSlicedData(sliced);
  }, [filteredData, pagesize, currentPage]);
  const handlePrevPage = () => { setCurrentPage(currentPage - 1); }
  const handleNextPage = () => { setCurrentPage(currentPage + 1); }
  const handleGoToPage = (page) => { setCurrentPage(page); }
  // HTML
  return (
    <div className='container-xxl'>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Müşteriler</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <h5 className='card-title mb-0"'>Müşteriler</h5>
              <div className="btn-group">
                <button className="btn btn-success rounded operationsdropdown d-flex align-items-center" onClick={toggleOperationsDropdown}><TbBook2 style={{ fontSize: '1.5em' }} />İşlemler<HiMiniChevronDown style={{ fontSize: '1.25em' }} /></button>
                {operationsDropdown && (
                  <div className="dropdown-menu operationsdropdown show" style={{ position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 40px)' }}>
                    <button className="dropdown-item"><TbUserPlus style={{ fontSize: '1.5em' }} /> Yeni Müşteri Ekle</button>
                    <button className="dropdown-item"><TbPrinter style={{ fontSize: '1.5em' }} />  Yazdır</button>
                  </div>
                )}
              </div>
            </div>
            <div className='card-body'>
              <div className='row d-flex justify-content-end mb-3'>
                <div className='col-xs-12 col-md-3'>
                  <TextField
                    label="Ara"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth />
                </div>
              </div>
              <Table responsive striped bordered className="w-100">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>AD-SOYAD</th>
                    <th>TELEFON</th>
                    <th>ADRES</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {slicedData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className='align-middle text-center'>{data.id}</td>
                        <td className='align-middle'>{data.customer.fullname}</td>
                        <td className='align-middle text-center'><h6 className='table-no-space'>{data.customer.phone}</h6></td>
                        <td className='align-middle'>
                          <div>
                            <h6 className='table-no-space'>{data.customer.address}</h6>
                            <h6 className='table-no-space'>{data.customer.district} / {data.customer.city}</h6>
                          </div>
                        </td>
                        <td className='align-middle'>
                          <div className="d-flex justify-content-center">
                            <button className="button-with-icon btn btn-primary mx-1"><TbUserCog className='button-icon' />Güncelle</button>
                            <button className="button-with-icon btn btn-warning mx-1"><TbUserExclamation className='button-icon' />Detay</button>
                            <button className="button-with-icon btn btn-danger mx-1"><TbUserMinus className='button-icon' />Sil</button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;