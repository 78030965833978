// General
import React, { useState } from 'react';

// React-Bootstrap
import { Table } from 'react-bootstrap';

// Icons
import { FiBarChart } from "react-icons/fi";

// Chart
import Chart from 'react-apexcharts';

function Surveys() {

  // Data
  const data = [
    { id: 1001, question: 'Teknisyen Dediği Saatte Geldi Mi ? ', Yes: 10, No: 20, notSure: 5 }, { id: 1002, question: 'Teknisyen Davranışlarından, Kılık ve Kıyafetlerinden Memnun Musunuz ?', Yes: 10, No: 5, notSure: 5 }, { id: 1003, question: 'Teknisyen Cihazınızla Yeterince İlgilendi Mi ?', Yes: 10, No: 20, notSure: 5 }, { id: 1004, question: 'Genel Olarak Servis Hizmetimizden Memnun Musunuz?', Yes: 10, No: 20, notSure: 5 }];


  // HTML
  return (
    <div className='container-xxl'>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Anketler</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 col-xl-12'>
          <div className='card overflow-hidden'>
            <div className="card-header">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0">Anket Tablosu</h5>
              </div>
            </div>
            <div className='card-body'>
              <Table responsive striped bordered className="w-100">
                <thead>
                  <tr>
                    <th >ANKET SORUSU</th>
                    <th className='text-center'>EVET </th>
                    <th className='text-center'>HAYIR </th>
                    <th className='text-center'>BELLİ DEĞİL </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td className='align-middle '>{data.question}</td>
                        <td className='align-middle text-center'>{data.Yes}</td>
                        <td className='align-middle text-center'>{data.No}</td>
                        <td className='align-middle text-center'>{data.notSure}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

            </div>
          </div>
        </div>
      </div>
      <div className='row d-flex'>
        {data.map((item) => {
          const chartData = {
            series: [item.Yes, item.No, item.notSure],
            options: {
              chart: {
                type: 'pie',
                width: '100%',
              },
              labels: ['Evet', 'Hayır', 'Bilmiyorum'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: '100%'
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
            }
          };
          return (
            <div key={item.id} className="col-md-6 col-xl-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <div className="border border-dark rounded-2 me-2 widget-icons-sections">
                      <FiBarChart className="widgets-icons" />
                    </div>
                    <h5 className="card-title mb-0">{item.question}</h5>
                  </div>
                </div>
                <div className="card-body ">
                  <Chart options={chartData.options} series={chartData.series} type="pie" height={200} />
                </div>
              </div>
            </div>
          );
        })}
      </div>


    </div>
  );
}

export default Surveys;