// General
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import { Form } from 'react-bootstrap';
import { TextField, InputAdornment, IconButton } from '@mui/material';
// Context
import { useAuth } from '../../../context/AuthContext';
// Icons
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FaDoorOpen } from "react-icons/fa";

function Login() {
  // Context Import
  const { setUsername, setPassword, isLoggedIn } = useAuth();
  // Variables
  const [ usernameTemp, setUsernameTemp ] = useState("");
  const [ passwordTemp, setPasswordTemp ] = useState("");
  const [ showPassword, setShowPassword ] = useState(false);
  const handleClickShowPassword = () => {setShowPassword(!showPassword)}
  // Function
  const handleLogin = (e) => {
    e.preventDefault();
    setUsername(usernameTemp);
    setPassword(passwordTemp);
  }
  const navigate = useNavigate();
  useEffect(() => {
    if(isLoggedIn) {
      navigate("/anasayfa");
    }
  }, [isLoggedIn, navigate]);
  // HTML
  return (
    <section className="vh-100 gradient-custom">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-light" style={{borderRadius: '1rem'}}>
              <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-3">
                  <h2 className="fw-bold mb-2 text-uppercase">ITS</h2>
                  <p className="text-black-50 mb-5">Tekrar hoş geldiniz! Lütfen giriş yapın.</p>
                  <Form className='w-100 h-100' onSubmit={handleLogin}>
                    <div className="mb-3">
                      <TextField
                        className='form-control'
                        label="Kullanıcı Adı"
                        value={usernameTemp}
                        onChange={(e) => setUsernameTemp(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth />
                    </div>
                    <div className="mb-3">
                      <TextField 
                        className="form-control"
                        label="Şifre"
                        value={passwordTemp}
                        onChange={(e) => setPasswordTemp(e.target.value)}
                        type={showPassword ? 'text' : 'password'} 
                        slotProps={{
                          input: {
                            endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                {showPassword ? <IoEye /> : <IoEyeOff />}
                              </IconButton>
                            </InputAdornment>)
                          }
                        }} 
                        variant="outlined" 
                        size="small" 
                        fullWidth />
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <button className="button-with-icon btn btn-outline-primary btn-lg w-100" type='submit'><FaDoorOpen className='button-icon' />Giriş Yap</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;