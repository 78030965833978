// General
import React, { useState } from 'react';
// React-Bootstrap
import { Table } from 'react-bootstrap';
// Select
import Select from 'react-select';

// formik and yup
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Material Ui
import { TextField, FormControl, FormHelperText } from '@mui/material';

function Bulkmessages() {
  // Data
  const data = [
    { id: 1001, customerName: 'Emir ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }, { id: 1002, customerName: 'Furkan ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }, { id: 1003, customerName: 'İsa ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }];

  // Variables to Add
  const cities = [{ value: 'İstanbul', label: 'İstanbul' }, { value: 'Ankara', label: 'Ankara' }, { value: 'İzmir', label: 'İzmir' },];
  const district = [{ value: 'Beylikdüzü', label: 'Beylikdüzü' }, { value: 'Mamak', label: 'Mamak' },]
  const devices = [{ value: 'İphone 16', label: 'İphone 16' }, { value: 'İphone 14', label: 'İphone 14' },];
  const brands = [{ value: 'Apple', label: 'Apple' }, { value: 'Samsung', label: 'Samsung' },]
  const sources = [{ value: 'Web', label: 'Web' }, { value: 'Çağrı Merkezi', label: 'Çağrı Merkezi' },];
  const status = [{ value: 'Tamamlandı', label: 'Tamamlandı' }, { value: 'Beklemede', label: 'Beklemede' },]
  const [sendMessage, setSendMessage] = useState('');
  const [selectCity, setselectCity] = useState(cities[0]);
  const [selectDistrict, setselectDistrict] = useState(district[0]);
  const [selectDevices, setselectDevice] = useState(devices[0]);
  const [selectBrands, setselectBrands] = useState(brands[0]);
  const [selectSources, setselectSources] = useState(sources[0]);
  const [selectStatus, setselectStatus] = useState(status[0]);




  // Validation
  const validationSchemaFilter = Yup.object().shape({
    startdate: Yup.date()
      .required('Lütfen Giriş tarihi Seçiniz'),
    enddate: Yup.date()
      .required('Lütfen Giriş tarihi Seçiniz').test('is-greater', 'Bitiş tarihi, başlangıç tarihinden sonra olmalıdır', function (value) {
        const { startdate } = this.parent;
        return value > startdate;
      })
      .notOneOf([Yup.ref('startdate')], 'Başlangıç tarihi ve bitiş tarihi aynı olamaz'),
    city: Yup.string().required('Lütfen bir şehir seçiniz.'),
    district: Yup.string().required('Lütfen bir ilçe seçiniz.'),
    brand: Yup.string().required('Lütfen bir marka seçiniz.'),
    device: Yup.string().required('Lütfen bir cihaz seçiniz.'),
    source: Yup.string().required('Lütfen bir kaynak seçiniz.'),
    status: Yup.string().required('Lütfen bir durum seçiniz.'),
    message: Yup.string()
      .min(5, 'Mesaj en az 5 karakter olmalıdır')
      .max(250, 'Mesaj en fazla 250 karakter olmalıdır')
      .required('Lütfen Mesaj Alanını doldurunuz.'),
  });

  // Handle Functions
  const handleSendMessage = (values) => {
    console.log(values)
  }


  // HTML
  return (
    <div className='container-xxl '>
      <div className='row'>
        <div className='col-xs-12 col-md-12'>
          <div className='card shadow mt-4'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Toplu SMS</h5>
            </div>
            <div className='card-body'>
              <Formik initialValues={{ startdate: '', enddate: '', city: '', district: '', brand: '', device: '', source: '', status: '', message: '' }} validationSchema={validationSchemaFilter} onSubmit={(values) => { handleSendMessage(values); }}>
                {({ errors, touched, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className='row d-flex justify-content-start  mb-0'>
                        <h5 className='mb-0 p-1'> Filtreler</h5>
                      </div>
                      <div className='row g-2 w-100'>
                        <div className="col-xs-12 col-md-3">
                          <Field
                            as={TextField}
                            name='startdate'
                            type="date"
                            variant='outlined'
                            size='small'
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.startdate && !!errors.startdate}
                            helperText={touched.startdate && errors.startdate}

                          />
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <Field
                            as={TextField}
                            name='enddate'
                            type="date"
                            variant='outlined'
                            size='small'
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.enddate && !!errors.enddate}
                            helperText={touched.enddate && errors.enddate}
                          />
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.city && !!errors.city}>
                            <Select
                              name='city'
                              placeholder="İl Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.city && errors.city ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.city && errors.city ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}

                              options={cities}
                              onChange={(selectedOption) => {
                                setFieldValue('city', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('city', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.city && errors.city && (
                              <FormHelperText>{errors.city}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.district && !!errors.district}>
                            <Select
                              name='district'
                              placeholder="İlçe Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.district && errors.district ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.district && errors.district ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}
                              options={district}
                              onChange={(selectedOption) => {
                                setFieldValue('district', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('district', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.district && errors.district && (
                              <FormHelperText>{errors.district}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                      <div className='row g-2 w-100'>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.brand && !!errors.brand}>
                            <Select
                              name='brand'
                              placeholder="Marka Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.brand && errors.brand ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.brand && errors.brand ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}
                              options={brands}
                              onChange={(selectedOption) => {
                                setFieldValue('brand', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('brand', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.brand && errors.brand && (
                              <FormHelperText>{errors.brand}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.brand && !!errors.brand}>
                            <Select
                              name='device'
                              placeholder="Cihaz Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.device && errors.device ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.device && errors.device ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}
                              options={devices}
                              onChange={(selectedOption) => {
                                setFieldValue('device', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('device', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.device && errors.device && (
                              <FormHelperText>{errors.device}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.source && !!errors.source}>
                            <Select
                              name='source'
                              placeholder="Kaynak Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.source && errors.source ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.source && errors.source ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}
                              options={sources}
                              onChange={(selectedOption) => {
                                setFieldValue('source', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('source', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.source && errors.source && (
                              <FormHelperText>{errors.source}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-xs-12 col-md-3">
                          <FormControl fullWidth size="small" error={touched.status && !!errors.status}>
                            <Select
                              name='status'
                              placeholder="Durum Seçiniz"
                              maxMenuHeight={200}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={false}
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: base => ({
                                  ...base,
                                  borderColor: touched.status && errors.status ? 'red' : base.borderColor, //  Hata varsa koyu kırmızı, 
                                  '&:hover': {
                                    borderColor: touched.status && errors.status ? 'red' : base.borderColor, // Hoverken hata varsa koyu kırmızı, 
                                  },
                                }),
                              }}
                              options={status}
                              onChange={(selectedOption) => {
                                setFieldValue('status', selectedOption.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('status', true); // Focus olduğunda erroru aktif etmek
                              }}
                            />
                            {touched.status && errors.status && (
                              <FormHelperText>{errors.status}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                      <div className='row g-2 d-flex justify-content-start  mb-0'>
                        <h5 className='mb-0 p-1'> Mesaj İçeriği</h5>
                      </div>
                      <div className='row g-1'>
                        <div className="col-xs-12 col-md-12">
                          <Field
                            as={TextField}
                            name='message'
                            label="Mesaj"
                            variant="outlined"
                            size="small"
                            slotProps={{ input: { resize: "none" } }}
                            multiline
                            minRows={4}
                            fullWidth
                            error={touched.message && !!errors.message}
                            helperText={touched.message && errors.message}
                          />
                        </div>
                      </div>
                      <div className='row g-2 d-flex justify-content-start  mb-0'>
                        <h5 className='mb-0 p-1'> Filtrelenen Tablo</h5>
                        <Table responsive striped bordered className="w-100">
                          <thead>
                            <tr>
                              <th>MÜŞTERİ ADI</th>
                              <th>İL</th>
                              <th>İLÇE</th>
                              <th>TELEFON</th>
                              <th>CİHAZ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((data, index) => {
                              return (
                                <tr key={data.id}>
                                  <td className='align-middle '>{data.customerName}</td>
                                  <td className='align-middle'>{data.City}</td>
                                  <td className='align-middle '>{data.District}</td>
                                  <td className='align-middle '>{data.Phone}</td>
                                  <td className='align-middle '>{data.Device}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="row g-2 d-flex justify-content-end align-items-center w-100">
                        <div className="col-xs-12 col-md-3">
                          <button type='submit' className="btn btn-success w-100">Mesajı Gönder</button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bulkmessages;