// General
import React from 'react';

function Invoices() {
  // HTML
  return (
    <div>Invoices</div>
  );
}

export default Invoices;