// General
import React from 'react';

function Checkout() {
  // HTML
  return (
    <div>Checkout</div>
  );
}

export default Checkout;