/* eslint-disable react-hooks/exhaustive-deps */
// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { FormControl, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
// Context
import { useData } from '../../../../context/DataContext';
import { useToast } from '../../../../context/ToastContext';
// Icons
import { TbFolderPlus, TbArrowBackUpDouble } from "react-icons/tb";

function ServiceAddModal({ show, handleClose, handleAdd }) {
  // Import Toast
  const { addToast } = useToast();
  // Variables To Add
  const { customers, brands, devices } = useData();
  const customerTypes = [{ value: 'Bireysel', label: 'Bireysel' }, { value: 'Kurumsal', label: 'Kurumsal' }];
  const warranties = [{ value: 0, label: 'Garanti Yok' }, { value: 6, label: '6 Ay' }, { value: 12, label: '12 Ay' }]
  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const [customerPhone1, setCustomerPhone1] = useState('');
  const [customerPhone2, setCustomerPhone2] = useState('');
  const [searchCustomer, setSearchCustomer] = useState(false);
  const [isPhone2Available, setIsPhone2Available] = useState(false);
  const [customerType, setCustomerType] = useState(customerTypes[0]);
  const [isCustomerFound, setIsCustomerFound] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerSurname, setCustomerSurname] = useState('');
  const [customerIdentityNo, setCustomerIdentityNo] = useState('');
  const [customerTaxNo, setCustomerTaxNo] = useState('');
  const [customerTaxOffice, setCustomerTaxOffice] = useState('');
  const [customerAddress, setCustomerAddress] = useState({ city: '', district: '', address: '' });
  const [deviceBrand, setDeviceBrand] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [deviceModel, setDeviceModel] = useState('');
  const [deviceProblem, setDeviceProblem] = useState('');
  const [serviceNote, setServiceNote] = useState('');
  const [deviceWarranty, setDeviceWarranty] = useState(warranties[0]);
  const [availableDate, setAvailableDate] = useState('');
  const [availableStartTime, setAvailableStartTime] = useState('');
  const [availableEndTime, setAvailableEndTime] = useState('');
  const [emergency, setEmergency] = useState(false);
  useEffect(() => {
    setCustomerPhone1('');
    setCustomerType(customerTypes[0])
    setCustomerName('');
    setCustomerSurname('');
    setCustomerIdentityNo('');
    setCustomerTaxNo('');
    setCustomerTaxOffice('');
    setCustomerAddress({ city: '', district: '', address: '' });
    setDeviceBrand(null);
    setDeviceType(null);
    setDeviceModel('');
    setDeviceProblem('');
    setServiceNote('');
    setDeviceWarranty(warranties[0]);
    setAvailableDate('');
    setAvailableStartTime('');
    setAvailableEndTime('');
  }, [show]);
  useEffect(() => {
    if (searchCustomer) {
      const firstphonefound = customers.find(item => item.phone1 === customerPhone1);
      const secondphonefound = customers.find(item => item.phone2 === customerPhone1);
      if (firstphonefound) {
        setCustomerType(firstphonefound.type === 'Bireysel' ? customerTypes[0] : customerTypes[1]);
        setCustomerPhone2(firstphonefound.phone2);
        setCustomerName(firstphonefound.name);
        setCustomerSurname(firstphonefound.surname);
        if (firstphonefound.type === 'Bireysel') {
          setCustomerIdentityNo(firstphonefound.identityNo);
          setCustomerTaxNo('');
          setCustomerTaxOffice('');
        } else {
          setCustomerIdentityNo('');
          setCustomerTaxNo(firstphonefound.taxNo);
          setCustomerTaxOffice(firstphonefound.taxOffice);
        }
        setCustomerAddress({ id: firstphonefound.id, title: firstphonefound.addressTitle, city: firstphonefound.addressCity, district: firstphonefound.addressDistrict, address: firstphonefound.addressText, postalCode: firstphonefound.addressPostalCode });
        setIsCustomerFound(true);
      }
      else if (secondphonefound) {
        setCustomerType(secondphonefound.type === 'Bireysel' ? customerTypes[0] : customerTypes[1]);
        setCustomerPhone2(secondphonefound.phone1);
        setCustomerName(secondphonefound.name);
        setCustomerSurname(secondphonefound.surname);
        if (secondphonefound.type === 'Bireysel') {
          setCustomerIdentityNo(secondphonefound.identityNo);
          setCustomerTaxNo('');
          setCustomerTaxOffice('');
        } else {
          setCustomerIdentityNo('');
          setCustomerTaxNo(secondphonefound.taxNo);
          setCustomerTaxOffice(secondphonefound.taxOffice);
        }
        setCustomerAddress({ id: secondphonefound.id, title: secondphonefound.addressTitle, city: secondphonefound.addressCity, district: secondphonefound.addressDistrict, address: secondphonefound.addressText, postalCode: secondphonefound.addressPostalCode });
        setIsCustomerFound(true);
      }
      else {
        setCustomerType('');
        setCustomerPhone2('');
        setCustomerName('');
        setCustomerSurname('');
        setCustomerType(customerTypes[0]);
        setCustomerIdentityNo('');
        setCustomerTaxNo('');
        setCustomerTaxOffice('');
        setCustomerAddress({ city: '', district: '', address: '' });
        setIsCustomerFound(false);
      }
    }
  }, [searchCustomer]);
  useEffect(() => {
    if (!isPhone2Available) {
      setCustomerPhone2('');
    }
  }, [isPhone2Available])
  useEffect(() => {
    if (!(numbers.includes(customerPhone1[customerPhone1.length - 1]))) {
      setCustomerPhone1(customerPhone1.slice(0, (customerPhone1.length - 1)));
    }
    if (customerPhone1.length > 10) {
      setCustomerPhone1(customerPhone1.slice(0, 10));
    }
    if (customerPhone1.length === 10) {
      setIsPhone2Available(true);
      setSearchCustomer(true);
    }
    else {
      setIsPhone2Available(false);
      setSearchCustomer(false);
      setCustomerPhone2('');
      setCustomerName('');
      setCustomerSurname('');
      setCustomerType(customerTypes[0]);
      setCustomerIdentityNo('');
      setCustomerTaxNo('');
      setCustomerTaxOffice('');
      setCustomerAddress({ city: '', district: '', address: '' });
      setIsCustomerFound(false);
    }
  }, [customerPhone1]);
  useEffect(() => {
    if (!(numbers.includes(customerPhone2[customerPhone2.length - 1]))) {
      setCustomerPhone2(customerPhone2.slice(0, (customerPhone2.length - 1)));
    }
    if (customerPhone2.length > 10) {
      setCustomerPhone2(customerPhone2.slice(0, 10));
    }
  }, [customerPhone2]);
  useEffect(() => {
    if (!(numbers.includes(customerIdentityNo[customerIdentityNo.length - 1]))) {
      setCustomerIdentityNo(customerIdentityNo.slice(0, (customerIdentityNo.length - 1)));
    }
    if (customerIdentityNo.length > 11) {
      setCustomerIdentityNo(customerIdentityNo.slice(0, 11));
    }
  }, [customerIdentityNo]);
  useEffect(() => {
    if (!(numbers.includes(customerTaxNo[customerTaxNo.length - 1]))) {
      setCustomerTaxNo(customerTaxNo.slice(0, (customerTaxNo.length - 1)));
    }
    if (customerTaxNo.length > 10) {
      setCustomerTaxNo(customerTaxNo.slice(0, 10));
    }
  }, [customerTaxNo]);
  // Date Operations
  const today = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
  const todayDate = new Date().toLocaleDateString();
  const formatDate = (inputDate) => {
    const d = new Date(inputDate);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const formatTime = (inputTime) => {
    const [hours, minutes] = inputTime.split(':');
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };
  const compareTimes = (start, end) => {
    const today = new Date().toISOString().split('T')[0];
    const startTime = new Date(`${today}T${start}:00`);
    const endTime = new Date(`${today}T${end}:00`);
    if (startTime < endTime) { return true; } else { return false; }
  };
  const formatDateForInput = (inputDate) => {
    const [day, month, year] = inputDate.split('.');
    return `${year}-${month}-${day}`;
  };

  const formatTimeForInput = (inputTime) => {
    const [hours, minutes] = inputTime.split('.');
    return `${hours}:${minutes}`;
  };
  // Handle Add
  const handleAddFunction = () => {
    if (customerPhone1 === '') {
      addToast({ background: 'danger', message: 'Lütfen bir telefon numarası giriniz', delay: 4000 });
    } else if (!(customerPhone2.length === 10 || customerPhone2.length === 0)) {
      addToast({ background: 'danger', message: 'Lütfen doğru bir 2. telefon numarası giriniz veya boş bırakınız', delay: 4000 });
    } else if (!(customerPhone1.length === 10)) {
      addToast({ background: 'danger', message: 'Telefon 10 haneli olmalıdır!', delay: 4000 });
    } else if (deviceBrand === null || deviceType === null || deviceModel === '' || deviceProblem === '') {
      addToast({ background: 'danger', message: 'Lütfen cihaza dair gerekli bilgileri giriniz!', delay: 4000 });
    } else if (customerName === '' || customerSurname === '' || customerAddress.city === '' || customerAddress.district === '' || customerAddress.address === '') {
      addToast({ background: 'danger', message: 'Lütfen müşteriye dair gerekli bilgileri giriniz!', delay: 4000 });
    } else if ((customerType.value === 'Kurumsal' && !(customerTaxNo.length === 10))) {
      addToast({ background: 'danger', message: 'Lütfen geçerli bir vergi numarası giriniz! (vergi numarası 10 haneli olmalıdır.)', delay: 4000 });
    } else if ((customerType.value === 'Kurumsal' && customerTaxOffice === '')) {
      addToast({ background: 'danger', message: 'Lütfen bir vergi dairesi giriniz!', delay: 4000 });
    } else if ((customerType.value === 'Bireysel' && !(customerIdentityNo.length === 11))) {
      addToast({ background: 'danger', message: 'Lütfen geçerli bir T.C. kimlik numarası giriniz! (Kimlik Numaranız 11 haneli olmalıdır.)', delay: 4000 });
    } else if (availableDate === '' || availableStartTime === '' || availableEndTime === '') {
      addToast({ background: 'danger', message: 'Lütfen müsait bir zaman seçiniz!', delay: 4000 });
    } else if (!compareTimes(availableStartTime, availableEndTime)) {
      addToast({ background: 'danger', message: 'Lütfen müsait zaman için bitiş saatini başlangıç saatinden daha geç olarak seçiniz!', delay: 4000 });
    } else {
      if (isCustomerFound) {
        const customerId = customers.find(item => item.phone1 === customerPhone1 || item.phone2 === customerPhone1).id;
        handleAdd({ style: 'onlyService', newCustomer: null, newService: { id: -1, userId: -1, customerId: customerId, brandId: deviceBrand, deviceId: deviceType, model: deviceModel, problem: deviceProblem, note: serviceNote, warranty: deviceWarranty.value, customerAviableDate: availableDate, customerAviableTimeStart: availableStartTime, customerAviableTimeEnd: availableEndTime, serviceDate: '', serviceTime: '', status: 'Servis Bekleniyor', emergency: emergency, createdAt: todayDate, updatedAt: todayDate } });
      } else {
        handleAdd({ style: 'withCustomer', newCustomer: { id: -1, name: customerName, surname: customerSurname, type: customerType.value, identityNo: customerIdentityNo, taxNo: customerTaxNo, taxOffice: customerTaxOffice, phone1: customerPhone1, phone2: customerPhone2, addressCity: customerAddress.city, addressDistrict: customerAddress.district, addressText: customerAddress.address, createdAt: todayDate, status: 'Active' }, newService: { id: -1, userId: -1, customerId: -1, brandId: deviceBrand, deviceId: deviceType, model: deviceModel, problem: deviceProblem, note: serviceNote, warranty: deviceWarranty.value, customerAviableDate: availableDate, customerAviableTimeStart: availableStartTime, customerAviableTimeEnd: availableEndTime, serviceDate: '', serviceTime: '', status: 'Servis Bekleniyor', emergency: emergency, createdAt: todayDate, updatedAt: todayDate } });
      }
    }
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl'>
      <Modal.Body>
        <div className='row p-2'>
          <div className='col-xs-12 col-md-12'>
            <div className='card shadow'>
              <div className='card-body d-flex justify-content-between align-items-center'>
                <h6>Tarih: {today}</h6>
                <div className="form-check mb-2">
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} value={emergency} onChange={e => setEmergency(e.target.value)} label="ACİL!" />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-6 d-flex align-items-stretch'>
            <div className='card shadow flex-fill'>
              <div className='card-header'>
                <h6>Müşteri Bilgileri</h6>
              </div>
              <div className='card-body d-flex justify-content-center align-items-center'>
                <div className='row g-2'>
                  <div className="col-xs-12 col-md-12">
                    <TextField
                      label="Telefon *"
                      variant="outlined"
                      value={customerPhone1}
                      onChange={e => setCustomerPhone1(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <TextField
                      label="2. Telefon"
                      variant="outlined"
                      value={customerPhone2}
                      onChange={e => setCustomerPhone2(e.target.value)}
                      size="small"
                      slotProps={{ input: { readOnly: !isPhone2Available } }}
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <FormControl fullWidth size="small">
                      <Select
                        label="Müşteri Tipi Seçiniz *"
                        maxMenuHeight={200}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        value={customerType}
                        onChange={selectedOption => setCustomerType(selectedOption)}
                        options={customerTypes.map(type => ({ value: type.value, label: type.label }))}
                      />
                    </FormControl>
                  </div>
                  {customerType.value === customerTypes[0].value && (
                    <div className="col-xs-12 col-md-12">
                      <TextField
                        label='T.C. Kimlik Numarası *'
                        variant="outlined"
                        size="small"
                        value={customerIdentityNo}
                        onChange={e => setCustomerIdentityNo(e.target.value)}
                        slotProps={{ input: { readOnly: isCustomerFound } }}
                        fullWidth
                      />
                    </div>
                  )}
                  {customerType.value === customerTypes[1].value && (
                    <>
                      <div className="col-xs-12 col-md-6">
                        <TextField
                          label='Vergi Numarası *'
                          variant="outlined"
                          size="small"
                          value={customerTaxNo}
                          onChange={e => setCustomerTaxNo(e.target.value)}
                          slotProps={{ input: { readOnly: isCustomerFound } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <TextField
                          label='Vergi Dairesi *'
                          variant="outlined"
                          size="small"
                          value={customerTaxOffice}
                          onChange={e => setCustomerTaxOffice(e.target.value)}
                          slotProps={{ input: { readOnly: isCustomerFound } }}
                          fullWidth
                        />
                      </div>
                    </>
                  )}
                  <div className="col-xs-12 col-md-6">
                    <TextField
                      label="Müşteri Adı *"
                      variant="outlined"
                      size="small"
                      value={customerName}
                      onChange={e => setCustomerName(e.target.value)}
                      slotProps={{ input: { readOnly: isCustomerFound } }}
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <TextField
                      label="Müşteri Soyadı *"
                      variant="outlined"
                      size="small"
                      value={customerSurname}
                      onChange={e => setCustomerSurname(e.target.value)}
                      slotProps={{ input: { readOnly: isCustomerFound } }}
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <TextField
                      label="İl *"
                      variant="outlined"
                      size="small"
                      value={customerAddress.city}
                      onChange={(e) => setCustomerAddress({ ...customerAddress, city: e.target.value })}
                      slotProps={{ input: { readOnly: isCustomerFound } }}
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <TextField
                      label="İlçe *"
                      variant="outlined"
                      size="small"
                      value={customerAddress.district}
                      onChange={(e) => setCustomerAddress({ ...customerAddress, district: e.target.value })}
                      slotProps={{ input: { readOnly: isCustomerFound } }}
                      fullWidth
                    />
                  </div>
                  <div className='col-xs-12 col-md-12'>
                    <TextField
                      label="Adres *"
                      variant="outlined"
                      value={customerAddress.address}
                      onChange={(e) => setCustomerAddress({ ...customerAddress, address: e.target.value })}
                      slotProps={{ input: { resize: "none", readOnly: isCustomerFound } }}
                      multiline
                      minRows={4}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-6 d-flex align-items-stretch'>
            <div className='card shadow flex-fill'>
              <div className='card-header'>
                <h6>Cihaz Bilgileri</h6>
              </div>
              <div className='card-body d-flex justify-content-between align-items-center'>
                <div className='row g-2'>
                  <div className="col-xs-12 col-md-6">
                    <FormControl fullWidth size="small">
                      <Select
                        placeholder="Marka Seçiniz *"
                        maxMenuHeight={200}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        value={brands.find(brand => brand.id === deviceBrand) ? { value: deviceBrand, label: brands.find(brand => brand.id === deviceBrand).name } : null}
                        onChange={(selectedOption) => setDeviceBrand(selectedOption.value)}
                        options={brands.map(brand => ({ value: brand.id, label: brand.name }))}
                      />
                    </FormControl>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <FormControl fullWidth size="small">
                      <Select
                        placeholder="Cihaz Seçiniz *"
                        maxMenuHeight={200}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        value={devices.find(device => device.id === deviceType) ? { value: deviceType, label: devices.find(device => device.id === deviceType).device } : null}
                        onChange={(selectedOption) => setDeviceType(selectedOption.value)}
                        options={devices.map(device => ({ value: device.id, label: device.device }))}
                      />
                    </FormControl>
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <TextField
                      label="Cihaz Modeli *"
                      variant="outlined"
                      value={deviceModel}
                      onChange={(e) => setDeviceModel(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className='col-xs-12 col-md-12'>
                    <TextField
                      label="Cihaz Problemi *"
                      variant="outlined"
                      value={deviceProblem}
                      onChange={(e) => setDeviceProblem(e.target.value)}
                      size="small"
                      slotProps={{ input: { resize: "none" } }}
                      multiline
                      minRows={2}
                      fullWidth
                    />
                  </div>
                  <div className='col-xs-12 col-md-12'>
                    <TextField
                      label="Operator Not"
                      variant="outlined"
                      value={serviceNote}
                      onChange={(e) => setServiceNote(e.target.value)}
                      size="small"
                      slotProps={{ input: { resize: "none" } }}
                      multiline
                      minRows={4}
                      fullWidth
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <FormControl fullWidth size="small">
                      <Select
                        placeholder="Garanti"
                        maxMenuHeight={200}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        value={deviceWarranty}
                        onChange={selectedOption => setDeviceWarranty(selectedOption)}
                        options={warranties}
                      />
                    </FormControl>
                  </div>
                  <div className='col-xs-12 col-md-2 d-flex justify-content-center align-items-center'>
                    <b>Müsait Zaman</b>
                  </div>
                  <div className='col-xs-12 col-md-4'>
                    <TextField
                      name='date'
                      type="date"
                      variant='outlined'
                      size='small'
                      value={formatDateForInput(availableDate)}
                      onChange={(e) => setAvailableDate(formatDate(e.target.value))}
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                    />
                  </div>
                  <div className='col-xs-6 col-md-3'>
                    <TextField
                      name='starttime'
                      type="time"
                      variant='outlined'
                      size='small'
                      value={formatTimeForInput(availableStartTime)}
                      onChange={(e) => setAvailableStartTime(formatTime(e.target.value))}
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                    />
                  </div>
                  <div className='col-xs-6 col-md-3'>
                    <TextField
                      name='endtime'
                      type="time"
                      variant='outlined'
                      size='small'
                      value={formatTimeForInput(availableEndTime)}
                      onChange={(e) => setAvailableEndTime(formatTime(e.target.value))}
                      slotProps={{ input: { shrink: "true" } }}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row g-2 d-flex justify-content-end align-items-center w-100'>
          <div className='col-xs-12 col-md-3'>
            <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
          </div>
          <div className='col-xs-12 col-md-3'>
            <button className='button-with-icon btn btn-success w-100' onClick={handleAddFunction}><TbFolderPlus className='button-icon' />Ekle</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceAddModal;