import { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = ({ background = '', icon = null, message = '', delay = 3000 }) => {
    const id = Date.now();
    const newToast = { id, background, icon, message, delay, show: true };
    setToasts((prevToasts) => [...prevToasts, newToast]);
    setTimeout(() => {
      removeToast(id);
    }, delay);
  };
  const removeToast = (id) => {
    setToasts((prevToasts) => 
      prevToasts.map((toast) =>
        toast.id === id ? { ...toast, show: false } : toast
      )
    );
  };
  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };