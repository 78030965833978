// General
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// Styles
import './assets/css/app.css';
import Toast from 'react-bootstrap/Toast';
// Context
import { AuthProvider } from './context/AuthContext';
import { DataProvider } from './context/DataContext';
import { ToastProvider, useToast } from './context/ToastContext';
// Pages
import Login from './views/pages/auth/Login';
import Layout from './views/Layout';
import Dashboard from './views/pages/dashboard/Dashboard';
import Services from "./views/pages/services/Services";
import Customers from "./views/pages/customers/Customers";
import Employees from "./views/pages/employees/Employees";
import Bulkmessages from "./views/pages/bulkmessage/Bulkmessages";
import Checkout from "./views/pages/checkout/Checkout";
import Invoices from "./views/pages/invoices/Invoices";
import Offers from "./views/pages/offers/Offers";
import Operationreports from "./views/pages/operationreports/Operationreports";
import Reminder from "./views/pages/reminder/Reminder";
import Statistics from "./views/pages/statistics/Statistics";
import Surveys from "./views/pages/surveys/Surveys";
import Warehose from "./views/pages/warehouse/Warehose";
import Error404 from "./views/pages/error/Error404";


function App() {
  return (
    <DataProvider>
      <ToastProvider>
        <AuthProvider>
          <Router>
            <AppContent />
          </Router>
        </AuthProvider>
      </ToastProvider>
    </DataProvider>
  );
}

function AppContent() {
  const { toasts, removeToast } = useToast();
  return (
    <div>
      <Routes>
        <Route path="/girisyap" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/girisyap" />} />
          <Route path="anasayfa" element={<Dashboard />} />
          <Route path="servisler" element={<Services />} />
          <Route path="musteriler" element={<Customers />} />
          <Route path="personeller" element={<Employees />} />
          <Route path="depo" element={<Warehose />} />
          <Route path="toplusms" element={<Bulkmessages />} />
          <Route path="kasa" element={<Checkout />} />
          <Route path="fatura" element={<Invoices />} />
          <Route path="teklifler" element={<Offers />} />
          <Route path="islemraporları" element={<Operationreports />} />
          <Route path="hatirlatici" element={<Reminder />} />
          <Route path="istatistikler" element={<Statistics />} />
          <Route path="anketler" element={<Surveys />} />
        </Route>
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <div className="position-fixed top-0 end-0 me-2" style={{ zIndex: 9999 }}>
        {toasts.map((toast) => (
          toast.show && (
            <Toast key={toast.id} show={toast.show} delay={toast.delay} autohide={true} bg={toast.background} className="mt-2" onClose={() => removeToast(toast.id)}>
              <Toast.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center text-white">
                    {toast.icon && toast.icon}
                    <h5 className="ms-2 p-0 m-0">{toast.message}</h5>
                  </div>
                  <div>
                    <button type="button" className="btn-close btn-close-white ms-auto" aria-label="Close" onClick={() => removeToast(toast.id)} />
                  </div>
                </div>
              </Toast.Body>
            </Toast>
          )))}
      </div>
    </div>
  );
}

export default App;