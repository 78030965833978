// General
import React from 'react';
import { NavLink } from 'react-router-dom';
// Context
import { useData } from '../../context/DataContext';
// Icons
import { FiHome, FiSettings, FiUser, FiUsers, FiLayers, FiCalendar, FiTag, FiMessageSquare } from "react-icons/fi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoStatsChartOutline, IoDocumentsOutline } from "react-icons/io5";
import { TbCashRegister, TbInvoice } from "react-icons/tb";
// Image
import ITSLogo from '../../assets/images/its.png';

function Sidebar() {
  // Context Import
  const { sidebar } = useData();
  // HTML
  return (
    <>
    {sidebar && (
      <div className="app-sidebar-menu">
        <div className="h-100">
          <div id="sidebar-menu">
            <div className="logo-box pt-4">
              <NavLink to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={ITSLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={ITSLogo} alt="" height="27" />
                </span>
              </NavLink>
              <NavLink to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={ITSLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={ITSLogo} alt="" height="27" />
                </span>
              </NavLink>
            </div>
            <ul id="side-menu">
              <li className="menu-title">MENÜ</li>
              <li>
                <NavLink to="/anasayfa" className="tp-link">
                  <FiHome /><span>Anasayfa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/servisler" className="tp-link">
                  <FiSettings /><span>Servisler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/musteriler" className="tp-link">
                  <FiUser /><span>Müşteriler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/personeller" className="tp-link">
                  <FiUsers /><span>Personeller</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/depo" className="tp-link">
                  <FiLayers /><span>Depo</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/teklifler" className="tp-link">
                  <FiTag /><span>Teklifler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/hatirlatici" className="tp-link">
                  <FiCalendar /><span>Hatırlatıcı</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/anketler" className="tp-link">
                  <HiOutlineClipboardDocumentList /><span>Anketler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/istatistikler" className="tp-link">
                  <IoStatsChartOutline /><span>İstatistikler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/kasa" className="tp-link">
                  <TbCashRegister /><span>Kasa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/fatura" className="tp-link">
                  <TbInvoice /><span>Fatura</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/toplusms" className="tp-link">
                  <FiMessageSquare /><span>Toplu SMS</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/islemraporları" className="tp-link">
                  <IoDocumentsOutline /><span>İşlem Raporları</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    )}
    </>
  );
}

export default Sidebar;