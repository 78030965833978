// General
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-bootstrap';
import Badge from '@mui/material/Badge';
// Context
import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';
// Icons
import { FiMenu } from "react-icons/fi";
import { HiMiniChevronDown } from "react-icons/hi2";
import { FaDoorClosed } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { BiMessageDetail } from "react-icons/bi";

function Header() {
  // Context Import
  const { sidebar, setSidebar, myUser } = useData();
  const { setUsername, setPassword } = useAuth();
  // Profile Dropdown
  const [ profileDropdown, setProfileDropdown ] = useState(false);
  const toggleProfileDropdown = () => {setProfileDropdown(!profileDropdown);};
  const handleClickOutside = useCallback((event) => {if (!event.target.closest('.dropdown')) {setProfileDropdown(false);}}, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {document.removeEventListener('click', handleClickOutside);};
  }, [handleClickOutside]);
  // Functions
  const handleLogout = () => {
    setUsername('');
    setPassword('');
  }
  // HTML
  return (
    <div className="topbar-custom" style={{left: `${sidebar ? '260px' : '0px'}`}}>
      <div className="d-flex justify-content-between">
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <button className="button-toggle-menu nav-link" onClick={() => setSidebar(!sidebar)}>
              <FiMenu className="align-middle fullscreen noti-icon" style={{fontSize: '1.8em'}} />
            </button>
          </li>
        </ul>
        <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
          <li>
            <NavLink>
              <Badge badgeContent={4} color="info">
                <BiMessageDetail style={{fontSize: '1.6em'}} />
              </Badge>
            </NavLink>
          </li>
          <li>
            <NavLink>
              <Badge badgeContent={75} color="warning">
                <IoNotificationsOutline style={{fontSize: '1.6em'}} />
              </Badge>
            </NavLink>
          </li>
          <li className="dropdown notification-list topbar-dropdown">
            <NavLink className="nav-link dropdown-toggle nav-user me-0" onClick={toggleProfileDropdown}>
              <img className="rounded-circle" src="https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg" alt="user-image" />
              <span className="pro-user-name ms-1">{myUser.name}</span>
              <HiMiniChevronDown className='ms-1' />
            </NavLink>
            {profileDropdown && (
              <div className="dropdown-menu dropdown-menu-end profile-dropdown show" style={{position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 72px)"}}>
                <button class="dropdown-item notify-item" onClick={handleLogout}>
                  <FaDoorClosed class="fs-16 align-middle me-2" />
                  <span>Çıkış</span>
                </button>     
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;