/* eslint-disable react-hooks/exhaustive-deps */
// General
import React, { useCallback, useState, useEffect } from 'react';
// Styles
import { TextField } from '@mui/material';
import { Table } from 'react-bootstrap';
// Context
import { useToast } from '../../../context/ToastContext';
import { useData } from '../../../context/DataContext';
// Icons
import { HiMiniChevronDown } from "react-icons/hi2";
import { TbBook2, TbFolderPlus, TbPrinter, TbFolderCog, TbFolderStar, TbFolderX } from "react-icons/tb";
// Modals
import ServiceAddModal from './modals/ServiceAddModal';
import ServiceUpdateModal from './modals/ServiceUpdateModal';
import ServiceDeleteModal from './modals/ServiceDeleteModal';

function Services() {
  // Import Toast
  const { addToast } = useToast();
  // Data Import
  const { users, customers, brands, devices, services } = useData();
  // Data Combine
  const [ combinedData, setCombinedData ] = useState([]);
  useEffect(() => {
    const combined = services.map((data) => {
      const id = data.id;
      const employeeData = users.find(item => item.id === data.userId);
      const employee = {
        name: employeeData.name,
        phone: employeeData.phone1
      }
      const customerData = customers.find(item => item.id === data.customerId);
      const customer = {
        name: customerData.name,
        surname: customerData.surname,
        phone: customerData.phone1,
        address: {
          title: customerData.addressTitle,
          city: customerData.addressCity,
          district: customerData.addressDistrict,
          address: customerData.addressText}
      }
      const device = {
        brand: brands.find(item => item.id === data.brandId).name,
        type: devices.find(item => item.id === data.deviceId).device,
        model: data.model,
        problem: data.problem,
        note: data.note,
        warranty: data.warranty
      }
      const date = data.serviceDate;
      const status = data.status;
      return { id: id, employee: employee, customer: customer, device: device, date: date, status: status};
    });
    setCombinedData(combined); 
  }, [services]);
  // Data Filtering
  const [ filteredData, setFilteredData ] = useState([]);
  const [ searchText, setSearchText ] = useState("");
  const searchCategories = [
    {layer: 1, layers: ["id"]},
    {layer: 2, layers: ["customer", "name"]},
    {layer: 2, layers: ["customer", "surname"]},
    {layer: 2, layers: ["customer", "phone"]},
    {layer: 3, layers: ["customer", "address", "city"]},
    {layer: 3, layers: ["customer", "address", "district"]},
    {layer: 3, layers: ["customer", "address", "address"]},
    {layer: 2, layers: ["device", "brand"]},
    {layer: 2, layers: ["device", "type"]},
    {layer: 2, layers: ["device", "model"]},
    {layer: 2, layers: ["device", "problem"]},
    {layer: 2, layers: ["device", "note"]},
  ]
  useEffect(() => {
    const filtered = combinedData.filter((item) => {
      return searchCategories.some((category) => {
        if (category.layer === 3) {
          const value = item[category.layers[0]]?.[category.layers[1]]?.[category.layers[2]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        } else if (category.layer === 2) {
          const value = item[category.layers[0]]?.[category.layers[1]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        } else {
          const value = item[category.layers[0]];
          return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
        }
      });
    });
    setFilteredData(filtered);
  }, [combinedData, searchText]);
  // Data Pagination
  const [ slicedData, setSlicedData ] = useState([]);
  const pagesize = 8;
  const totalPages = Math.ceil(filteredData.length / pagesize);
  const [ currentPage, setCurrentPage ] = useState(1);
  useEffect(() => {
    const startIndex = (currentPage - 1) * pagesize;
    const sliced = filteredData.slice(startIndex, startIndex + pagesize);
    setSlicedData(sliced);
  }, [filteredData, pagesize, currentPage]);
  const handlePrevPage = () => {setCurrentPage(currentPage - 1);}
  const handleNextPage = () => {setCurrentPage(currentPage + 1);}
  const handleGoToPage = (page) => {setCurrentPage(page);}
  // Operations Dropdown
  const [ operationsDropdown, setOperationsDropdown ] = useState(false);
  const toggleOperationsDropdown = () => {setOperationsDropdown(!operationsDropdown);};
  const handleClickOutside = useCallback((event) => {if (!event.target.closest('.operationsdropdown')) {setOperationsDropdown(false);}}, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {document.removeEventListener('click', handleClickOutside);};
  }, [handleClickOutside]);
  // Modals
  // Service Add Modal
  const [ showServiceAddModal, setShowServiceAddModal ] = useState(false);
  const handleCloseServiceAddModal = () => {setShowServiceAddModal(false)};
  const handleShowServiceAddModal = () => {setShowServiceAddModal(true)};
  const handleAddServiceAddModal = (values) => {
    if(values.style === 'onlyService'){
      console.log('Servis Eklendi: ', values.newService);
    } else {
      console.log('Müşteri Eklendi: ', values.newCustomer);
      console.log('Servis Eklendi: ', values.newService);
    }
    addToast({ background: 'success', icon: <TbFolderPlus style={{fontSize: '1.5em'}} />, message: 'Servis Eklendi!', delay: 6000 });
    handleCloseServiceAddModal();
  };
  // Service Update Modal
  const [ showServiceUpdateModal, setShowServiceUpdateModal ] = useState(false);
  const [ inputDataForServiceUpdateModal, setInputDataForServiceUpdateModal ] = useState({});
  const handleCloseServiceUpdateModal = () => {
    setShowServiceUpdateModal(false)
    setInputDataForServiceUpdateModal({});
  };
  const handleShowServiceUpdateModal = (data) => {
    setInputDataForServiceUpdateModal(data);
    setShowServiceUpdateModal(true);
  };
  const handleUpdateServiceUpdateModal = (values) => {
    console.log('Servis güncellendi: ', inputDataForServiceUpdateModal, ' --> ', values);
    addToast({ background: 'primary', icon: <TbFolderCog style={{fontSize: '1.5em'}} />, message: 'Servis Güncellendi!', delay: 6000 });
    handleCloseServiceUpdateModal();
  };
  // Service Delete Modal
  const [ showServiceDeleteModal, setShowServiceDeleteModal ] = useState(false);
  const [ inputDataForServiceDeleteModal, setInputDataForServiceDeleteModal ] = useState(-1);
  const handleCloseServiceDeleteModal = () => {
    setShowServiceDeleteModal(false)
    setInputDataForServiceDeleteModal(-1);
  };
  const handleShowServiceDeleteModal = (data) => {
    setInputDataForServiceDeleteModal(data);
    setShowServiceDeleteModal(true);
  };
  const handleDeleteServiceDeleteModal = (values) => {
    console.log(values, ' id numaralı servis silindi.');
    addToast({ background: 'danger', icon: <TbFolderX style={{fontSize: '1.5em'}} />, message: 'Servis Silindi!', delay: 6000 });
    handleCloseServiceDeleteModal();
  };
  // Helper Functions
  const findServiceData = (id) => {
    return services.find(item => item.id === id);
  }
  // HTML
  return (
    <div className="container-xxl mb-5">
      <div className="row">
        <div className="col-12">
          <div className="card mt-4">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Servisler</h5>
              <div className="btn-group">
                <button className="btn btn-success rounded operationsdropdown d-flex align-items-center" onClick={toggleOperationsDropdown}><TbBook2 style={{fontSize: '1.5em'}} />İşlemler<HiMiniChevronDown style={{fontSize: '1.25em'}} /></button>
                {operationsDropdown && (
                  <div className="dropdown-menu operationsdropdown show" style={{position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 40px)'}}>
                    <button className="dropdown-item" onClick={handleShowServiceAddModal}><TbFolderPlus style={{fontSize: '1.5em'}} />  Yeni Servis Ekle</button>
                    <button className="dropdown-item"><TbPrinter style={{fontSize: '1.5em'}} />  Yazdır</button>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className='row d-flex justify-content-end mb-3'>
                <div className='col-xs-12 col-md-3'>
                  <TextField
                    label="Ara"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth />
                </div>
              </div>
              <Table responsive striped bordered className="w-100">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>MÜŞTERİ</th>
                    <th>CİHAZ</th>
                    <th>TARİH</th>
                    <th>NOT</th>
                    <th>DURUM</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {slicedData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className='align-middle'>{data.id}</td>
                        <td className='align-middle'>
                          <div>
                            <b className='table-no-space'>{data.customer.name} {data.customer.surname}</b>
                            <h6 className='table-no-space'>{data.customer.phone}</h6>
                            <h6 className='table-no-space'>{data.customer.address.address}</h6>
                            <h6 className='table-no-space'>{data.customer.address.district} / {data.customer.address.city}</h6>
                          </div>
                        </td>
                        <td className='align-middle'>
                          <div>
                            <h6 className='table-no-space'>{data.device.brand} / {data.device.type}</h6>
                            <h6 className='table-no-space'>{data.device.modal}</h6>
                          </div>
                        </td>
                        <td className='align-middle'>{data.date}</td>
                        <td className='align-middle'>{data.device.note}</td>
                        <td className='align-middle'>{data.status}</td>
                        <td className='align-middle'>
                          <div className="d-flex justify-content-center">
                            <button className="button-with-icon btn btn-primary mx-1" onClick={() => handleShowServiceUpdateModal(findServiceData(data.id))}><TbFolderCog className='button-icon' />Güncelle</button>
                            <button className="button-with-icon btn btn-warning mx-1"><TbFolderStar className='button-icon' />Detay</button>
                            <button className="button-with-icon btn btn-danger mx-1" onClick={() => handleShowServiceDeleteModal(data.id)}><TbFolderX className='button-icon' />Sil</button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className='row'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div><h6>{currentPage}. Sayfada {services.length} Veri Üzerinden {slicedData.length} Veri Gösteriliyor</h6></div>
                  <ul className="pagination">
                    <li className={`paginate_button page-item previus ${currentPage === 1 && 'disabled'}`}>
                      <button className="page-link" onClick={() => handleGoToPage(1)}>{'<<'}</button>
                    </li>
                    <li className={`paginate_button page-item ${currentPage === 1 && 'disabled'}`}>
                      <button className="page-link" onClick={handlePrevPage}>{'<'}</button>
                    </li>
                    {currentPage - 2 > 0 && (
                      <li className="paginate_button page-item">
                        <button className="page-link" onClick={() => handleGoToPage(currentPage - 2)}>{currentPage - 2}</button>
                      </li>
                    )}
                    {currentPage - 1 > 0 && (
                      <li className="paginate_button page-item">
                        <button className="page-link" onClick={() => handleGoToPage(currentPage - 1)}>{currentPage - 1}</button>
                      </li>
                    )}
                    <li className="paginate_button page-item active">
                      <button className="page-link">{currentPage}</button>
                    </li>
                    {currentPage + 1 <= totalPages && (
                      <li className="paginate_button page-item">
                        <button className="page-link" onClick={() => handleGoToPage(currentPage + 1)}>{currentPage + 1}</button>
                      </li>
                    )}
                    {currentPage + 2 <= totalPages && (
                      <li className="paginate_button page-item">
                        <button className="page-link" onClick={() => handleGoToPage(currentPage + 2)}>{currentPage + 2}</button>
                      </li>
                    )}
                    <li className={`paginate_button page-item ${currentPage === totalPages && 'disabled'}`}>
                      <button className="page-link" onClick={handleNextPage}>{'>'}</button>
                    </li>
                    <li className={`paginate_button page-item next ${currentPage === totalPages && 'disabled'}`}>
                      <button className="page-link" onClick={() => handleGoToPage(totalPages)}>{'>>'}</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ServiceAddModal 
        show={showServiceAddModal}
        handleClose={handleCloseServiceAddModal}
        handleAdd={handleAddServiceAddModal}
      />
      <ServiceUpdateModal 
        show={showServiceUpdateModal}
        handleClose={handleCloseServiceUpdateModal}
        handleUpdate={handleUpdateServiceUpdateModal}
        inputData={inputDataForServiceUpdateModal}      
      />
      <ServiceDeleteModal 
        show={showServiceDeleteModal}
        handleClose={handleCloseServiceDeleteModal}
        handleDelete={handleDeleteServiceDeleteModal}
        inputData={inputDataForServiceDeleteModal}
      />
    </div>
  );
}

export default Services;